import { ChakraProvider } from '@chakra-ui/react';
import { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import App, { AppContext, AppProps } from 'next/app';
import { PageDataProvider, RequestLink } from 'lib/hooks/PageDataContext';
import axios from 'lib/api/axios';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import OpenReplay from '@openreplay/tracker';
import universalLanguageDetect from '@unly/universal-language-detector';
import {
  FpjsProvider,
  FingerprintJSPro
} from '@fingerprintjs/fingerprintjs-pro-react'

import Head from 'next/head';
import Layout from 'components/layout';
import createEmotionCache from 'styles/createEmotionCache';
import customTheme from 'styles/customTheme';
import 'styles/globals.css';
import { createSwatch } from '../lib/swatch';
import { QueryClient, QueryClientProvider } from 'react-query';
import { get } from 'lodash-es';
import { I18nProvider } from '../lib/hooks/I18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});
const clientSideEmotionCache = createEmotionCache();

const openReplay = new OpenReplay({
  projectKey: 'W3DmsfuYhueIOPFUSDqg',
  ingestPoint: "https://replay.zippyassist.com/ingest",
  obscureInputEmails: false,
  obscureInputNumbers: false,
  obscureTextEmails: false,
  obscureTextNumbers: false,
  defaultInputMode: 0,
  __DISABLE_SECURE_MODE: process.env.NEXT_PUBLIC_ENV === 'development',
  network: {
    sessionTokenHeader: 'X-OpenReplay-SessionToken',
    capturePayload: true,
    failuresOnly: false,
    ignoreHeaders: false,
  }
});

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const MyApp = ({
  Component,
  pageProps,
  router,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: String(process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGEMENT_ID)
    });

    openReplay.start();
  }, []);

  const brandColor = pageProps?.owner ? createSwatch(pageProps?.owner.theme_color) : null;

  return (
    <FpjsProvider
      loadOptions={{
        apiKey: String(process.env.NEXT_PUBLIC_FINGERPRINT_KEY),
        endpoint: [
          'https://warden.zippyassist.com',
          FingerprintJSPro.defaultEndpoint
        ],
        scriptUrlPattern: [
          'https://warden.zippyassist.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
          FingerprintJSPro.defaultScriptUrlPattern
        ]
      }}
    >
      <CacheProvider value={emotionCache}>
        <ChakraProvider theme={customTheme}>
          <Head>
            <title>ZippyAssist</title>

            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
            />

            {
              brandColor && <style dangerouslySetInnerHTML={{
                __html: `
                :root {
                  --zippy-colors-brand-50: ${brandColor[0]};
                  --zippy-colors-brand-100: ${brandColor[1]};
                  --zippy-colors-brand-200: ${brandColor[2]};
                  --zippy-colors-brand-300: ${brandColor[3]};
                  --zippy-colors-brand-400: ${brandColor[4]};
                  --zippy-colors-brand-500: ${brandColor[5]};
                  --zippy-colors-brand-600: ${brandColor[6]};
                  --zippy-colors-brand-700: ${brandColor[7]};
                  --zippy-colors-brand-800: ${brandColor[8]};
                  --zippy-colors-brand-900: ${brandColor[9]};
                }
              `
              }}/>
            }
          </Head>
          <QueryClientProvider client={queryClient}>
            <PageDataProvider defaultData={{
              owner: pageProps?.owner,
              token: pageProps?.token,
              requestLink: pageProps?.requestLink as any,
              settings: pageProps.settings,
              refundMethods: pageProps.refundMethods,
            } as any}>
              <I18nProvider translations={pageProps.translations} locale={pageProps.locale}>
                <Layout>
                  <Component {...pageProps} key={router.route} />
                </Layout>
              </I18nProvider>
            </PageDataProvider>
          </QueryClientProvider>
        </ChakraProvider>
      </CacheProvider>
    </FpjsProvider>
  );
};

MyApp.defaultProps = {
  emotionCache: clientSideEmotionCache,
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { company_slug, token, rl: requestLinkId } = appContext.router.query;

  if (!company_slug) {
    return { ...appProps };
  }

  if (appContext?.ctx?.req?.headers?.cookie) {
    axios.defaults.headers.common['cookie'] = appContext.ctx.req.headers.cookie;
  }

  try {
    const pageProps = {
      owner: await (await axios.get(`/api/owner/${company_slug}`))?.data,
      refundMethods: [],
      settings: {} as any,
      translations: {} as any,
      token: null,
      requestLink: null,
      locale: null as any
    };

    const lang = universalLanguageDetect({
      supportedLanguages: pageProps.owner.supported_languages,
      fallbackLanguage: 'en',
      acceptLanguageHeader: appContext.ctx.query.lang || get(appContext.ctx.req, 'headers.accept-language')
    });

    pageProps.locale = lang;
    pageProps.settings = pageProps.owner.settings;

    pageProps.translations = await (await axios.get(`/api/owner/${company_slug}/translations?locale=${lang}`))?.data;

    if (requestLinkId) {
      pageProps.requestLink = await (await axios.get(`/api/request_link/${requestLinkId}`))?.data;
    } else if (token) {
      pageProps.token = await (await axios.get(`/api/token/${token}`))?.data;
    }

    const isRefundsActive = pageProps.owner.features.refunds && pageProps.settings.refunds_active && (
      pageProps.owner.refund_methods.length > 0 ||
      pageProps.owner.payment_methods.filter(p => p.pre_auth_refund_method_type === 'Reversal').length > 0
    );

    pageProps.settings.refunds_active = isRefundsActive;

    if (pageProps.settings.refunds_active) {
      pageProps.refundMethods = await (await axios.get(`/api/owner/${company_slug}/refund/methods`)).data;
    }

    // Figure out how to support i18n, just adding this will cache the last request, which may be in a different language
    // to current request. Also need to check that it can work with unique tokens.
    // appContext.ctx.res?.setHeader(
    //   'Cache-Control',
    //   'public, s-maxage=10, stale-while-revalidate=59'
    // )

    return { ...appProps, pageProps };
  } catch (e) {
    appContext.ctx.res?.writeHead(302, { Location: '/' });
    appContext.ctx.res?.end();

    return { props: {} };
  }
}

export default MyApp;
